/* eslint-disable react/no-array-index-key */
import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

// Images
import Dashes from 'img/dashes.inline.svg'
import Arrow from 'img/arrow.inline.svg'

// Components
import Plaatjie from '@ubo/plaatjie'
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled, { css } from 'styled-components'

interface BannerHomeProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_FlexHome_Banner
}

const ImageWrapper = styled.div`
  height: 825px;
  position: relative;
  margin: 0 0 150px 0;

  @media screen and (max-width: 991px) {
    margin: 0 0 15px 0;
    height: fit-content;
  }
`

const Gradient = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  ${({ theme }) => theme.gradient.redToOpaque};
  mix-blend-mode: multiply;

  @media screen and (max-width: 991px) {
    height: 350px;
  }

  @media screen and (max-width: 575px) {
    height: 220px;
  }
`

const ContentWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 3;
  padding: 200px 0 0 0;

  @media screen and (max-width: 991px) {
    position: relative;
    padding: 0;
    height: fit-content;
    margin-top: -120px;

    & h1 {
      font-size: 30px;
      line-height: 35px;
      padding-bottom: 20px;
    }
  }
`

const StyledPlaatjie = styled(Plaatjie)`
  height: 100%;

  & img {
    object-position: 50% 100% !important;
  }

  @media screen and (max-width: 991px) {
    height: 350px;
  }

  @media screen and (max-width: 575px) {
    height: 220px;
  }
`

const Block = styled.div`
  background-color: ${({ theme }) => theme.color.light};
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.25);
  padding: 60px 80px;
  height: 100%;

  @media screen and (max-width: 991px) {
    padding: 20px 30px;
  }
`

const RedTitle = styled.div`
  font-size: 36px;
  line-height: 45px;
  font-weight: ${({ theme }) => theme.font.weight.bold};

  & svg {
    margin-right: 10px;
    & path {
      fill: ${({ theme }) => theme.color.primary};
    }
  }
`

const StyledLink = styled(Link)<{ c: string }>`
  font-size: 18px;
  line-height: 22px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  display: flex;
  align-items: center;
  transition: all 0.3s ease;

  & svg {
    margin-left: 10px;

    & path {
      transition: all 0.3s ease;
    }
  }

  &:hover {
    ${({ theme, c }) =>
      c === 'primary' &&
      css`
        color: ${theme.color.primary};
      `};

    ${({ theme, c }) =>
      c === 'secondary' &&
      css`
        color: ${theme.color.secondary};
      `};

    & svg {
      & path {
        ${({ theme, c }) =>
          c === 'primary' &&
          css`
            fill: ${theme.color.primary};
          `};

        ${({ theme, c }) =>
          c === 'secondary' &&
          css`
            fill: ${theme.color.secondary};
          `};
      }
    }
  }

  @media screen and (max-width: 991px) {
    line-height: 16px;
    font-size: 18px;
  }
`

const ContentRow = styled.div`
  position: absolute;
  width: 100%;
  bottom: -90px;

  @media screen and (max-width: 991px) {
    position: relative;
    width: unset;
    bottom: 0;
  }
`

const BannerHome: React.FC<BannerHomeProps> = ({ fields }) => {
  const query = useStaticQuery<GatsbyTypes.bannerBusinessQuery>(graphql`
    query bannerBusiness {
      business: allWpBusiness {
        nodes {
          title
          uri
        }
      }
    }
  `)

  return (
    <section>
      <ImageWrapper>
        <Gradient />
        <StyledPlaatjie image={fields.image} alt="" loading="eager" />
        <ContentWrapper>
          <div className="container h-100 position-relative">
            <ParseContent content={fields.description || ''} />
            <ContentRow className="row justify-content-between">
              <div className="col-lg-8">
                <Block>
                  <RedTitle className="d-flex align-items-center">
                    <Dashes />
                    Zakelijk
                  </RedTitle>
                  <div className="row pt-4">
                    {query.business.nodes.map((b, index) => (
                      <div className="col-md-6">
                        <div className="my-2 py-2">
                          <StyledLink c="primary" to={b.uri || '/'} key={index}>
                            {b.title}
                            <Arrow />
                          </StyledLink>
                        </div>
                      </div>
                    ))}
                  </div>
                </Block>
              </div>
            </ContentRow>
          </div>
        </ContentWrapper>
      </ImageWrapper>
    </section>
  )
}

export default BannerHome
