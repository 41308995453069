import React from 'react'

// Components
import Plaatjie from '@ubo/plaatjie'
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'

interface BannerBackgroundImageTextProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_FlexHome_Banner
}

const Wrapper = styled.div`
  height: fit-content;
  position: relative;
`

const StyledPlaatjie = styled(Plaatjie)`
  position: absolute !important;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
`

const Gradient = styled.div`
  position: absolute !important;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 3;
  ${({ theme }) => theme.gradient.blackToOpaque};

  @media screen and (max-width: 991px) {
    background: rgba(0, 0, 0, 0.6);
  }
`

const ContentWrapper = styled.div`
  padding: 140px 0;
  position: relative;
  z-index: 4;

  & p {
    line-height: 25px;
  }

  @media screen and (max-width: 991px) {
    padding: 30px 0;
  }
`

const Content = styled(ParseContent)`
  @media screen and (min-width: 992px) {
    max-width: 600px;
  }
`

const BannerBackgroundImageText: React.FC<BannerBackgroundImageTextProps> = ({
  fields,
}) => (
  <section>
    <Wrapper>
      <StyledPlaatjie image={fields.image} alt="" />
      <Gradient />
      <ContentWrapper>
        <div className="container">
          <div className="d-flex justify-content-end">
            <Content content={fields.description || ''} />
          </div>
        </div>
      </ContentWrapper>
    </Wrapper>
  </section>
)

export default BannerBackgroundImageText
