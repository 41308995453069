/* eslint-disable react/no-array-index-key */
import React from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'

interface BannerGreyUspProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_FlexHome_Banner
}

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.color.grey};
`

const USP = styled.div`
  @media screen and (max-width: 991px) {
    padding: 15px 30px;

    & img {
      width: 50px;
      height: 50px;
      object-fit: contain !important;
    }
  }
`

const BannerGreyUsp: React.FC<BannerGreyUspProps> = ({ fields }) => (
  <section>
    <Wrapper>
      <div className="container py-lg-5">
        <div className="py-lg-4 py-2 row justify-content-evenly">
          {fields.usps?.map((usp, index) => (
            <div key={index} className="col-lg-4">
              <USP className="d-flex align-items-center">
                <img
                  className="me-4"
                  alt=""
                  src={usp?.icon?.localFile?.publicURL}
                />
                <ParseContent content={usp?.description || ''} />
              </USP>
            </div>
          ))}
        </div>
      </div>
    </Wrapper>
  </section>
)

export default BannerGreyUsp
