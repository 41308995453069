import React from 'react'

// Components
import BannerHome from 'components/flex/Banner/BannerHome'
import BannerBusiness from 'components/flex/Banner/BannerBusiness'
import BannerPrivate from 'components/flex/Banner/BannerPrivate'
import BannerGreyUsp from 'components/flex/Banner/BannerGreyUsp'
import BannerBackgroundImageText from 'components/flex/Banner/BannerBackgroundImageText'
import BannerMoreInformation from 'components/flex/Banner/BannerMoreInformation'

interface BannerShellProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_FlexHome_Text
  location?: any
}

interface PostProps {
  [key: string]: any
}

const BannerShell: React.FC<BannerShellProps> = ({ fields, location = {} }) => {
  const types: PostProps = {
    home: BannerHome,
    business: BannerBusiness,
    private: BannerPrivate,
    greyusp: BannerGreyUsp,
    backgroundimagetext: BannerBackgroundImageText,
    moreinformation: BannerMoreInformation,
  }

  if (!fields.styletype || !types[fields.styletype]) {
    return null
  }

  const Component = types[fields.styletype]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
    />
  )
}

export default BannerShell
